import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import admin from "./modules/admin";
import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["auth", "admin"],
    }),
  ],
  state: {
    appLanguage: localStorage.getItem("appLanguage") || 'nl',
    languageDialog: false,
    dialog: false,
    messaging: null,
    collapsed: localStorage.getItem("collapsed") || false,
  },
  getters: {
    getAppLanguage: (state) => state.appLanguage,
    getCollapsed: (state) => state.collapsed
  },
  mutations: {
    setAppLanguage(state, language) {
      state.appLanguage = language;
      localStorage.setItem("appLanguage", language); // Whenever we change the appLanguage we save it to the localStorage
    },
    setLanguageDialog(state, languageDialog) {
      state.languageDialog = languageDialog;
    },
    setDialog(state, dialog) {
      state.dialog = dialog;
    },
    setMessaging(state, messaging) {
      state.messaging = messaging;
    },
    toggleSidebar(state) {
      localStorage.setItem("collapsed", !state.collapsed);
      state.collapsed = !state.collapsed;
    },
  },
  modules: {
    auth,
    admin,
    app,
  },
});