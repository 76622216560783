import Vue from "vue";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, onMessage } from 'firebase/messaging';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/plugins/apexcharts";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDqkjAtT8RRDMo-FAOsGTcaEDn1iocPMpo",
  authDomain: "delooye-vitalve.firebaseapp.com",
  projectId: "delooye-vitalve",
  storageBucket: "delooye-vitalve.appspot.com",
  messagingSenderId: "910711972511",
  appId: "1:910711972511:web:1ef9a9482948af9b07f8aa",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);
store.commit('setMessaging', messaging);

// Display messag  when there is a FCM while the application is open:
onMessage(messaging, (payload) => {
  // Possible handling of notifications when app is active
});

getAuth().onAuthStateChanged((user) => {
  if (user) {
    store.commit("auth/setUser", user);
  } else {
    store.commit("auth/setUser", null);
  }
});

// Axios Config
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:5001/delooye-vitalve/europe-west1/' : 'https://europe-west1-delooye-vitalve.cloudfunctions.net/';

axios.interceptors.request.use(async (config) => {
  if (store.state.auth.user) {
    const token = await store.state.auth.user.getIdToken(true);
    config.headers["Authorization"] = "Bearer " + token;
  }
  if (store.state.auth.organization) {
    config.headers["X-Organization"] = store.state.auth.organization;
  }

  return config;
});

// Vue Config
Vue.config.productionTip = false;

Vue.use(Toast);

Vue.component("country-flag", CountryFlag);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
