<template lang="pug">
router-view
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
* {
  font-family: "Poppins", sans-serif !important;
}
.loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  color: var(--v-primary-base);
}
.theme--light.v-application {
  background: var(--v-background-base) !important;
}
</style>
