import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        background: "#edf8f2",
        primary: "#368860",
        secondary: "#18ade4",
        tertiary: "#f2bf3a",
        quaternary: "#c68c51",
        quinary: "#2F4858",
        accent: "#f8ba17",
        error: "#d08845",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      dark: {
        primary: "#368860",
        secondary: "#fbfdfc",
      },
    },
  },
});
