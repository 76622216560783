import axios from "axios";
import i18n from "@/i18n";

// initial state
const getDefaultState = () => ({
  // monitor
  currentMonitor: null,
  pastMonitor: null,
  currentMonitorLoading: false,
  pastMonitorLoading: false,

  // pots
  pots: [],
  potsLoading: {
    allPots: false,
    singlePots: [],
  },

  // foods
  foods: [],
  foodsLoading: {
    allFoods: false,
    singleFoods: [],
  },

  // rations
  rations: [],
  rationsLoading: {
    allRations: false,
    singleRations: [],
  },

  // measurements
  measurements: [],
  measurementsLoading: {
    allMeasurements: false,
    singleMeasurements: [],
  },

  // pickup moments
  pickupMoments: [],
  pickupMomentsLoading: {
    allPickupMoments: false,
    singlePickupMoments: [],
  },

  // memos
  memos: [],
  memosLoading: {
    allMemos: false,
    singleMemos: [],
  },
  // monitor
  monitorMemos: null,
  monitorMemosLoading: false,

  // monitor settings
  monitorSettings: null,
  monitorSettingsLoading: false,
});

const state = getDefaultState();

// getters
const getters = {
  // monitor
  getCurrentMonitor(state) {
    return state.currentMonitor;
  },
  getPastMonitor(state) {
    return state.pastMonitor;
  },

  // pots
  getPots(state) {
    return state.pots;
  },

  // foods
  getFoods(state) {
    return state.foods;
  },

  // rations
  getRations(state) {
    let rations = []
    if (state.rations.length > 0) {
      // state.ratoions are flattened rows, so if a ration has 4 foods then it has 4 rows with all a unique id but the same ration_id
      const rationIds = [...new Set(state.rations.map((x) => x.ration_id))]; // create array of unique ration_id's
      rationIds.forEach((ration_id) => {
        // filter the rows that have the current ration_id
        let rowsWithRationId = state.rations.filter(f => f.ration_id === ration_id);

        // loop over rows in ration
        const foodIds = state.foods.map(f => f.id);
        let foodsInRation = [];
        rowsWithRationId.forEach(r => {
          // find the food object associated with the food_id in ration
          const index = foodIds.indexOf(r.food_id);
          const food = state.foods[index];
          foodsInRation.push({...food, kg_food: parseFloat(r.kg_food)});
        })

        // create object with ration id and name of ration
        let obj = {
          ration_id: rowsWithRationId[0].ration_id,
          name: rowsWithRationId[0].name,
          created_at: rowsWithRationId[0].created_at,
          foods: foodsInRation
        };
        rations.push(obj)
      });
    }
    return rations;
  },

  // measurements
  getMeasurements(state) {
    return state.measurements;
  },

  // pickup moments
  getPickupMoments(state) {
    return state.pickupMoments;
  },

  // memos
  getMemos(state) {
    return state.memos;
  },
  getMonitorMemos(state) {
    return state.monitorMemos;
  },

  // monitor settings
  getMonitorSettings(state) {
    if(state.monitorSettings){
      return JSON.parse(JSON.stringify(state.monitorSettings));
    }
    return state.monitorSettings;
  },
};

// actions
const actions = {
  // monitor
  async getCurrentMonitor({ commit }, query) {
    commit("setCurrentMonitorLoading", true);
    try {
      let res;
      if (query.start === undefined || query.end === undefined) {
        res = await axios.get("get_monitor");
      } else {
        let url = "get_monitor?start=" + query.start + "&end=" + query.end;
        if (query.labels) {
          const labelsInQuery = query.labels.map(encodeURIComponent);
          url = url + "&labels[]=" + labelsInQuery.join("&labels[]=");;
        }
        if (query.organization_id){
          url = url + "&organization_id=" + query.organization_id
        }
        res = await axios.get(url);
      }
      if (res.status === 200) {
        // set monitor data
        commit("setCurrentMonitor", res.data);
      } else {
        commit("setCurrentMonitor", null);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setCurrentMonitorLoading", false);
    }
  },
  async getPastMonitor({ commit }, query) {
    commit("setPastMonitorLoading", true);
    try {
      let res;
      if (query.start === undefined || query.end === undefined) {
        res = await axios.get("get_monitor");
      } else {
        let q = "get_monitor?start=" + query.start + "&end=" + query.end;
        if (query.labels) {
          const labelsInQuery = query.labels.map(encodeURIComponent);
          q = q + "&labels[]=" + labelsInQuery.join("&labels[]=");
        }
        res = await axios.get(q);
      }
      if (res.status === 200) {
        // set monitor data
        commit("setPastMonitor", res.data);
      } else {
        commit("setPastMonitor", null);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setPastMonitorLoading", false);
    }
  },

  // pots
  async getAllPots({ commit }) {
    commit("clearSinglePotsLoading");
    commit("setAllPotsLoading", true);
    try {
      const res = await axios.get("get_all_pots");
      if (res.status === 200) {
        commit("setPots", res.data);
      } else {
        commit("setPots", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllPotsLoading", false);
    }
  },
  async createPot({ commit }, pot) {
    this._vm.$toast.info(i18n.t('common.busyCreatingItem', {msg: pot.name}), {
      timeout: false,
    });
    try {
      const res = await axios.post("create_pot", pot);
      if (res.status === 200) {
        commit("addPot", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemCreated', {msg: pot.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async updatePot({ commit }, pot) {
    commit("addPotIdToSinglePotsLoading", pot.id);
    this._vm.$toast.info(i18n.t('common.busyUpdatingItem', {msg: pot.name}), {
      timeout: false,
    });
    try {
      let potToSend = {
        id: pot.id,
        name: pot.name,
        label: pot.label,
        animals: pot.animals || 0,
        ignore: pot.ignore
      };
      const res = await axios.post("update_pot", potToSend);
      if (res.status === 200) {
        commit("removePot", pot.id);
        commit("addPot", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemUpdated', {msg: pot.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removePotIdFromSinglePotsLoading", pot.id);
    }
  },
  async deletePot({ commit }, pot) {
    commit("addPotIdToSinglePotsLoading", pot.id);
    this._vm.$toast.info(i18n.t('common.busyDeletingItem', {msg: pot.name}), {
      timeout: false,
    });
    try {
      const res = await axios.post("delete_pot", { id: pot.id });
      if (res.status === 204) {
        commit("removePot", pot.id);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemDeleted', {msg: pot.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removePotIdFromSinglePotsLoading", pot.id);
    }
  },

  // foods
  async getAllFoodsAndRations({ commit }) {
    commit("clearSingleFoodsLoading");
    commit("clearSingleRationsLoading");
    commit("setAllFoodsLoading", true);
    commit("setAllRationsLoading", true);
    try {
      const [resFoods, resRations] = await Promise.all([axios.get("get_all_foods"), axios.get("get_all_rations")]);
      
      if (resFoods.status === 200 && resRations.status === 200) {
        commit("setFoods", resFoods.data);
        commit("setRations", resRations.data);
      } else {
        commit("setFoods", []);
        commit("setRations", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllFoodsLoading", false);
      commit("setAllRationsLoading", false);
    }
  },
  async createFood({ commit }, food) {
    this._vm.$toast.info(i18n.t('common.busyCreatingItem', {msg: food.name}), {
      timeout: false,
    });
    commit("setAllRationsLoading", true); // we don't want someone making changes to rations while food is being created
    try {
      const res = await axios.post("create_food", food);
      if (res.status === 200) {
        commit("addFood", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemCreated', {msg: food.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("setAllRationsLoading", false);
    }
  },
  async updateFood({ commit }, food) {
    commit("addFoodIdToSingleFoodsLoading", food.id);
    commit("setAllRationsLoading", true); // we don't want someone making changes to rations while food is being updated
    this._vm.$toast.info(i18n.t('common.busyUpdatingItem', {msg: food.name}), {
      timeout: false,
    });
    try {
      let foodToSend = {
        id: food.id,
        name: food.name,
        label_1: food.label_1,
        label_2: food.label_2,
        ds_percentage: parseFloat(food.ds_percentage) || 0,
        price_per_kg: parseFloat(food.price_per_kg) || 0,
        multiply: food.multiply,
      };
      const res = await axios.post("update_food", foodToSend);
      if (res.status === 200) {
        // add food to state (get version_id from response, because it has changed)
        let foodToAdd = JSON.parse(JSON.stringify(food));
        foodToAdd.version_id = res.data;
        commit("updateFood", foodToAdd);

        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemUpdated', {msg: food.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeFoodIdFromSingleFoodsLoading", food.id);
      commit("setAllRationsLoading", false);
    }
  },
  async deleteFood({ commit }, food) {
    commit("addFoodIdToSingleFoodsLoading", food.id);
    commit("setAllRationsLoading", true); // we don't want someone making changes to rations while food is being deleted
    this._vm.$toast.info(i18n.t('common.busyDeletingItem', {msg: food.name}), {
      timeout: false,
    });
    try {
      const res = await axios.post("delete_food", { id: food.id });
      if (res.status === 204) {
        commit("removeFood", food.id);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemDeleted', {msg: food.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeFoodIdFromSingleFoodsLoading", food.id);
      commit("setAllRationsLoading", false);
    }
  },

  // rations
  async createRation({ commit }, ration) {
    this._vm.$toast.info(i18n.t('common.busyCreatingItem', {msg: ration.ration.name}), {
      timeout: false,
    });
    try {
      const res = await axios.post("create_ration", ration);
      if (res.status === 200) {
        commit("addRation", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemCreated', {msg: ration.ration.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async updateRation({ commit }, ration) {
    // in the backend we need to put the ration in the payload as { id: ration_id }, so in this function ration.id is actually ration.ration_id
    commit("addRationIdToSingleRationsLoading", ration.id);
    this._vm.$toast.info(i18n.t('common.busyUpdatingItem', {msg: ration.ration.name}), {
      timeout: false,
    });
    try {
      const res = await axios.post("update_ration", ration);
      if (res.status === 200) {
        commit("removeRation", ration.id);
        commit("addRation", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemUpdated', {msg: ration.ration.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeRationIdFromSingleRationsLoading", ration.id);
    }
  },
  async deleteRation({ commit }, ration) {
    commit("addRationIdToSingleRationsLoading", ration.ration_id);
    this._vm.$toast.info(i18n.t('common.busyDeletingItem', {msg: ration.name}), {
      timeout: false,
    });
    try {
      const res = await axios.post("delete_ration", { id: ration.ration_id });
      if (res.status === 204) {
        commit("removeRation", ration.ration_id);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemDeleted', {msg: ration.name}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeRationIdFromSingleRationsLoading", ration.ration_id);
    }
  },

  // measurements
  async getAllMeasurements({ commit }) {
    commit("clearSingleMeasurementsLoading");
    commit("setAllMeasurementsLoading", true);
    try {
      const res = await axios.get("get_all_measurements");
      if (res.status === 200) {
        commit("setMeasurements", res.data);
      } else {
        commit("setMeasurements", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllMeasurementsLoading", false);
    }
  },
  async createMeasurement({ dispatch }, measurement) {
    this._vm.$toast.info(i18n.t('common.busyCreatingMeasurement'), {
      timeout: false,
    });
    try {
      const res = await axios.post("create_measurement", measurement);
      if (res.status === 200) {
        await dispatch("getAllMeasurements");
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.measurementCreated"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async updateMeasurement({ commit, dispatch }, measurement) {
    commit(
      "addMeasurementIdToSingleMeasurementsLoading",
      measurement.measurements_per_pot[0].measurement_id
    );
    this._vm.$toast.info(i18n.t('common.busyUpdatingMeasurement'), {
      timeout: false,
    });
    try {
      let measurement_to_send = {
        remove: measurement.measurements_per_pot.map((m) => m.measurement_id),
        create: {
          created_at: measurement.created_at,
          created_by: measurement.created_by,
          measurements_per_pot: measurement.measurements_per_pot.map((mpp) => ({
            extra: mpp.extra_to_send,
            kg_food_per_day: mpp.ration.kg_food,
            kg_milk_per_day: mpp.kg_milk_per_day,
            percentage_restfood: measurement.percentage_restfood,
            pot_version_id: mpp.pot.version_id,
            ration_id: mpp.ration.ration_id,
          })),
        },
      };
      const res = await axios.post("update_measurement", measurement_to_send);
      if (res.status === 200) {
        await dispatch("getAllMeasurements");
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.measurementUpdated"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removeMeasurementIdFromSingleMeasurementsLoading",
        measurement.measurements_per_pot[0].measurement_id
      );
    }
  },
  async deleteMeasurement({ commit, dispatch }, measurement) {
    commit(
      "addMeasurementIdToSingleMeasurementsLoading",
      measurement.measurements_per_pot[0].measurement_id
    );
    this._vm.$toast.info(i18n.t('common.busyDeletingMeasurement'), {
      timeout: false,
    });
    try {
      const measurement_ids = measurement.measurements_per_pot.map(m => m.measurement_id);
      const res = await axios.post("delete_measurement", measurement_ids);
      if (res.status === 200) {
        await dispatch("getAllMeasurements");
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.measurementDeleted"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removeMeasurementIdFromSingleMeasurementsLoading",
        measurement.measurements_per_pot[0].measurement_id
      );
    }
  },

  // pickup moments
  async getAllPickupMoments({ commit }) {
    commit("clearSinglePickupMomentsLoading");
    commit("setAllPickupMomentsLoading", true);
    try {
      const res = await axios.get("get_all_pickup_moments");
      if (res.status === 200) {
        commit("setPickupMoments", res.data);
      } else {
        commit("setPickupMoments", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllPickupMomentsLoading", false);
    }
  },
  async createPickupMoment({ commit }, pickupMoment) {
    this._vm.$toast.info(i18n.t('common.busyCreatingPickupMoment'), {
      timeout: false,
    });
    try {
      const res = await axios.post("create_pickup_moment", pickupMoment);
      if (res.status === 200) {
        commit("addPickupMoment", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.pickupMomentCreated"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async updatePickupMoment({ commit }, pickupMoment) {
    commit("addPickupMomentIdToSinglePickupMomentsLoading", pickupMoment.id);
    this._vm.$toast.info(i18n.t("common.busyUpdatingPickupMoment"), {
      timeout: false,
    });
    try {
      let pickupMomentToSend = {
        id: pickupMoment.id,
        kg_milk: pickupMoment.kg_milk,
        fat: pickupMoment.fat || 0,
        protein: pickupMoment.protein || 0,
        ureum: pickupMoment.ureum || 0,
        lactose: pickupMoment.lactose || 0,
        amount_of_milk_moments: pickupMoment.amount_of_milk_moments,
        created_at: pickupMoment.created_at,
      };
      const res = await axios.post("update_pickup_moment", pickupMomentToSend);
      if (res.status === 204) {
        commit("removePickupMoment", pickupMoment.id);
        pickupMoment.fat = pickupMoment.fat || 0;
        pickupMoment.protein = pickupMoment.protein || 0;
        pickupMoment.ureum = pickupMoment.ureum || 0;
        pickupMoment.lactose = pickupMoment.lactose || 0;
        commit("addPickupMoment", pickupMoment);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.pickupMomentUpdated"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removePickupMomentIdFromSinglePickupMomentsLoading",
        pickupMoment.id
      );
    }
  },
  async deletePickupMoment({ commit }, pickupMoment) {
    commit("addPickupMomentIdToSinglePickupMomentsLoading", pickupMoment.id);
    this._vm.$toast.info(i18n.t("common.busyDeletingPickupMoment"), {
      timeout: false,
    });
    try {
      const res = await axios.post("delete_pickup_moment", {
        id: pickupMoment.id,
      });
      if (res.status === 204) {
        commit("removePickupMoment", pickupMoment.id);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.pickupMomentDeleted"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removePickupMomentIdFromSinglePickupMomentsLoading",
        pickupMoment.id
      );
    }
  },

  // memos
  async getAllMemos({ commit }) {
    commit("clearSingleMemosLoading");
    commit("setAllMemosLoading", true);
    try {
      const res = await axios.get("get_all_memos");
      if (res.status === 200) {
        commit("setMemos", res.data);
      } else {
        commit("setMemos", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllMemosLoading", false);
    }
  },
  async getMonitorMemos({ commit }, query) {
    commit("setMonitorMemosLoading", true);
    try {
      let url = "get_memos?start=" + query.start + "&end=" + query.end;
      const res = await axios.get(url);
      if (res.status === 200) {
        commit("setMonitorMemos", res.data);
      } else {
        commit("setMonitorMemos", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setMonitorMemosLoading", false);
    }
  },
  async createMemo({ commit }, memo) {
    this._vm.$toast.info(i18n.t('common.busyCreatingItem', {msg: 'memo'}), {
      timeout: false,
    });
    try {
      let memoToSend = {
        id: memo.id,
        text: memo.text,
        updated_at: memo.updated_at,
      };
      const res = await axios.post("create_memo", memoToSend);
      if (res.status === 200) {
        commit("addMemo", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemCreated', {msg: 'Memo'}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async updateMemo({ commit }, memo) {
    commit("addMemoIdToSingleMemosLoading", memo.id);
    this._vm.$toast.info(i18n.t('common.busyUpdatingItem', {msg: 'memo'}), {
      timeout: false,
    });
    try {
      let memoToSend = {
        id: memo.id,
        text: memo.text,
        updated_at: memo.updated_at,
      };
      const res = await axios.post("update_memo", memoToSend);
      if (res.status === 204) {
        commit("removeMemo", memo.id);
        commit("addMemo", memoToSend);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemUpdated', {msg: 'Memo'}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeMemoIdFromSingleMemosLoading", memo.id);
    }
  },
  async deleteMemo({ commit }, memo) {
    commit("addMemoIdToSingleMemosLoading", memo.id);
    this._vm.$toast.info(i18n.t('common.busyDeletingItem', {msg: 'memo'}), {
      timeout: false,
    });
    try {
      const res = await axios.post("delete_memo", { id: memo.id });
      if (res.status === 204) {
        commit("removeMemo", memo.id);
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t('common.itemDeleted', {msg: 'Memo'}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeMemoIdFromSingleMemosLoading", memo.id);
    }
  },

  // PDF
  async createPDF(_, pdf) {
    this._vm.$toast.info(i18n.t('common.busyCreatingPDF'), {
      timeout: false,
    });
    try {
      const res = await axios.post("create_pdf", {
        data: pdf
      });
      if (res.status === 200) {
        this._vm.$toast.clear();
        return res.data;
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async mailPDF(_, pdf) {
    this._vm.$toast.info(i18n.t('common.busyCreatingPDF'), {
      timeout: false,
    });
    try {
      const res = await axios.post("mail_pdf", {
        data: pdf
      });
      if (res.status === 204) {
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.emailSend"));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async getPDF(_, id) {
    const res = await axios.get("get_pdf", {
      params: {
        id
      }
    });
    return res.data;
  },

  // monitor settings
  async getMonitorSettings({ commit }) {
    commit("setMonitorSettingsLoading", true);
    try {
      const res = await axios.get("get_monitor_settings");
      if (res.status === 200) {
        commit("setMonitorSettings", res.data);
      } else {
        commit("setMonitorSettings", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setMonitorSettingsLoading", false);
    }
  },
};

// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  // monitor
  setCurrentMonitorLoading(state, bool) {
    state.currentMonitorLoading = bool;
  },
  setPastMonitorLoading(state, bool) {
    state.pastMonitorLoading = bool;
  },
  setCurrentMonitor(state, monitor) {
    state.currentMonitor = monitor;
  },
  setPastMonitor(state, monitor) {
    state.pastMonitor = monitor;
  },

  // pots
  setPots(state, pots) {
    state.pots = pots;
  },
  addPot(state, pot) {
    state.pots.push(pot);
  },
  removePot(state, id) {
    for (var i = 0; i < state.pots.length; i++) {
      if (state.pots[i].id === id) {
        state.pots.splice(i, 1);
      }
    }
  },
  setAllPotsLoading(state, bool) {
    state.potsLoading.allPots = bool;
  },
  addPotIdToSinglePotsLoading(state, id) {
    state.potsLoading.singlePots.push(id);
  },
  removePotIdFromSinglePotsLoading(state, id) {
    for (var i = 0; i < state.potsLoading.singlePots.length; i++) {
      if (state.potsLoading.singlePots[i] === id) {
        state.potsLoading.singlePots.splice(i, 1);
      }
    }
  },
  clearSinglePotsLoading(state) {
    state.potsLoading.singlePots = [];
  },

  // foods
  setFoods(state, foods) {
    state.foods = foods;
  },
  addFood(state, food) {
    state.foods.push(food);
  },
  updateFood(state, food) {
    for (var i = 0; i < state.foods.length; i++) {
      if (state.foods[i].id === food.id) {
        state.foods.splice(i,1);
        state.foods.push(food);
      }
    }
  },
  removeFood(state, id) {
    for (var i = 0; i < state.foods.length; i++) {
      if (state.foods[i].id === id) {
        state.foods[i].active = false;
      }
    }
  },
  setAllFoodsLoading(state, bool) {
    state.foodsLoading.allFoods = bool;
  },
  addFoodIdToSingleFoodsLoading(state, id) {
    state.foodsLoading.singleFoods.push(id);
  },
  removeFoodIdFromSingleFoodsLoading(state, id) {
    for (var i = 0; i < state.foodsLoading.singleFoods.length; i++) {
      if (state.foodsLoading.singleFoods[i] === id) {
        state.foodsLoading.singleFoods.splice(i, 1);
      }
    }
  },
  clearSingleFoodsLoading(state) {
    state.foodsLoading.singleFoods = [];
  },

  // rations
  setRations(state, rations) {
    state.rations = rations;
  },
  addRation(state, ration) {
    ration.ration_food_distributions.forEach((f) => {
      const rationToAdd = {
        created_at: ration.created_at,
        food_id: f.food_id,
        food_version_id: f.food_version_id,
        id: f.id,
        kg_food: f.kg_food,
        name: ration.name,
        organization_id: ration.organization_id,
        ration_id: ration.id,
      };
      state.rations.push(rationToAdd);
    });
  },
  removeRation(state, ration_id) {
    state.rations = state.rations.filter(
      (item) => item.ration_id !== ration_id
    );
  },
  setAllRationsLoading(state, bool) {
    state.rationsLoading.allRations = bool;
  },
  addRationIdToSingleRationsLoading(state, ration_id) {
    state.rationsLoading.singleRations.push(ration_id);
  },
  removeRationIdFromSingleRationsLoading(state, ration_id) {
    for (var i = 0; i < state.rationsLoading.singleRations.length; i++) {
      if (state.rationsLoading.singleRations[i] === ration_id) {
        state.rationsLoading.singleRations.splice(i, 1);
      }
    }
  },
  clearSingleRationsLoading(state) {
    state.rationsLoading.singleRations = [];
  },

  // measurements
  setMeasurements(state, measurements) {
    state.measurements = measurements;
  },
  addMeasurement(state, measurement) {
    state.measurements.push(measurement);
  },
  removeMeasurement(state, id) {
    const newArray = [];
    for (const measurement of state.measurements) {
      if (!measurement.measurements_per_pot.some(mpp => mpp.measurement_id === id)) {
        newArray.push(measurement);
      }
    }
    state.measurements = newArray;
  },
  setAllMeasurementsLoading(state, bool) {
    state.measurementsLoading.allMeasurements = bool;
  },
  addMeasurementIdToSingleMeasurementsLoading(state, id) {
    state.measurementsLoading.singleMeasurements.push(id);
  },
  removeMeasurementIdFromSingleMeasurementsLoading(state, id) {
    for (
      var i = 0;
      i < state.measurementsLoading.singleMeasurements.length;
      i++
    ) {
      if (state.measurementsLoading.singleMeasurements[i] === id) {
        state.measurementsLoading.singleMeasurements.splice(i, 1);
      }
    }
  },
  clearSingleMeasurementsLoading(state) {
    state.measurementsLoading.singleMeasurements = [];
  },

  // pickup Moments
  setPickupMoments(state, pickupMoments) {
    state.pickupMoments = pickupMoments;
  },
  addPickupMoment(state, pickupMoment) {
    state.pickupMoments.push(pickupMoment);
  },
  removePickupMoment(state, id) {
    for (var i = 0; i < state.pickupMoments.length; i++) {
      if (state.pickupMoments[i].id === id) {
        state.pickupMoments.splice(i, 1);
      }
    }
  },
  setAllPickupMomentsLoading(state, bool) {
    state.pickupMomentsLoading.allPickupMoments = bool;
  },
  addPickupMomentIdToSinglePickupMomentsLoading(state, id) {
    state.pickupMomentsLoading.singlePickupMoments.push(id);
  },
  removePickupMomentIdFromSinglePickupMomentsLoading(state, id) {
    for (
      var i = 0;
      i < state.pickupMomentsLoading.singlePickupMoments.length;
      i++
    ) {
      if (state.pickupMomentsLoading.singlePickupMoments[i] === id) {
        state.pickupMomentsLoading.singlePickupMoments.splice(i, 1);
      }
    }
  },
  clearSinglePickupMomentsLoading(state) {
    state.pickupMomentsLoading.singlePickupMoments = [];
  },

  // memos
  setMemos(state, memos) {
    state.memos = memos;
  },
  addMemo(state, memo) {
    state.memos.push(memo);
  },
  removeMemo(state, id) {
    for (var i = 0; i < state.memos.length; i++) {
      if (state.memos[i].id === id) {
        state.memos.splice(i, 1);
      }
    }
  },
  setAllMemosLoading(state, bool) {
    state.memosLoading.allMemos = bool;
  },
  addMemoIdToSingleMemosLoading(state, id) {
    state.memosLoading.singleMemos.push(id);
  },
  removeMemoIdFromSingleMemosLoading(state, id) {
    for (var i = 0; i < state.memosLoading.singleMemos.length; i++) {
      if (state.memosLoading.singleMemos[i] === id) {
        state.memosLoading.singleMemos.splice(i, 1);
      }
    }
  },
  clearSingleMemosLoading(state) {
    state.memosLoading.singleMemos = [];
  },

  setMonitorMemosLoading(state, bool) {
    state.monitorMemosLoading = bool;
  },
  setMonitorMemos(state, memos) {
    state.monitorMemos = memos;
  },

  // monitor settings
  setMonitorSettings(state, monitorSettings) {
    state.monitorSettings = monitorSettings;
  },
  setMonitorSettingsLoading(state, bool) {
    state.monitorSettingsLoading = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
