import axios from "axios";

// initial state
const getDefaultState = () => ({
  user: null,
  organization: null,
  organization_data: null,
  premium: false,
});

const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {
  // organizations
  async getAllOrganizations() {
    const res = await axios.get("get_all_organizations");
    return res.data;
  },
  async getOrganization(context) {
    const res = await axios.get("get_organization");
    context.commit("setOrganizationData", res.data);
  },

  // password
  async completeResetPassword(context, payload) {
    await axios.post("complete_reset_password", {
      password: payload.password,
      code: payload.code,
    });
  },

  // notifications
  async saveNotificationToken(context, token) {
    await axios.post('save_notification_token', {
      token
    });
  },
};

// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  
  setUser(state, user) {
    state.user = user;
  },
  setOrganization(state, organization) {
    state.organization = organization;
    state.organization_data = null;
  },
  setOrganizationData(state, organization_data) {
    state.organization_data = organization_data;
  },
  setPremium(state, premium) {
    state.premium = premium;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
