import axios from "axios";
import i18n from "@/i18n";

// initial state
const getDefaultState = () => ({
  settings: {
    showOrganizationsOfType: "all",
    showHiddenOrganizationsType: "active",
    updateSupplierLoading: false
  },
  potCourses: [],
  potCoursesLoading: {
    allPotCourses: false,
  },
  organizations: [],
  organizationsLoading: {
    allOrganizations: false,
    singleOrganizations: [],
  },
  users: [],
  usersLoading: {
    allUsers: false,
    singleUsers: [],
  },
  admins: [],
  monitorSettings: [],
  monitorSettingsLoading: {
    allMonitorSettings: false,
    singleMonitorSettings: [],
  },
});

const state = getDefaultState();

// getters
const getters = {
  getOrganizations(state) {
    // if filter has been set on "goats" or "cows" then only show those organizations
    let organizations = JSON.parse(JSON.stringify(state.organizations));
    if (state.settings.showOrganizationsOfType !== "all") {
      organizations = organizations.filter(
        (org) => org.label === state.settings.showOrganizationsOfType
      );
    }
    if(state.settings.showHiddenOrganizationsType !== "all") {
      if(state.settings.showHiddenOrganizationsType == "active"){
        organizations = organizations.filter(org => !org.hidden);
      }
      if(state.settings.showHiddenOrganizationsType == "non-active"){
        organizations = organizations.filter(org => org.hidden);
      }
    }

    return organizations;
  },
  getAllOrganizations(state) {
    return JSON.parse(JSON.stringify(state.organizations));
  },
  getUsers(state) {
    return state.users;
  },
  getAdmins(state) {
    return state.admins;
  },
  getMonitorSettings(state) {
    return JSON.parse(JSON.stringify(state.monitorSettings));
  },
};

// actions
const actions = {
  // pot course
  async getAllPotCourses({ commit }) {
    commit("setAllPotCoursesLoading", true);
    let allPotCourses = []
    try {
      const res = await axios.get("get_all_pot_courses");
      if (res.status === 200) {
        allPotCourses = res.data;
      } 
    } catch (e) {
      this._vm.$toast.error(i18n.t('common.errorFetchingData'));
      console.error(e);
    } finally {
      commit("setAllPotCoursesLoading", false);
    }
    return allPotCourses
  },

  // organization
  async getAllOrganizations({ commit }) {
    commit("clearSingleOrganizationsLoading");
    commit("setAllOrganizationsLoading", true);
    try {
      const resAdmins = await axios.get("get_all_users?scope=admin");
      const res = await axios.get("get_all_organizations");
      if (resAdmins.status === 200 && res.status === 200) {
        commit("setAdmins", resAdmins.data);
        commit("setOrganizations", res.data);
      } else {
        commit("setAdmins", []);
        commit("setOrganizations", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllOrganizationsLoading", false);
    }
  },
  async createOrganization({ commit }, organization) {
    this._vm.$toast.info(
      i18n.t("common.busyCreatingItem", { msg: organization.name }),
      { timeout: false }
    );
    try {
      const org = {
        organization: {
          name: organization.name,
          vitalisator:
            organization.vitalisator.constructor === Array
              ? organization.vitalisator[0]
              : organization.vitalisator,
          label: organization.label,
          country: organization.country,
          mail_list: organization.mail_list || [],
          location: organization.location,
          hidden: organization.hidden
        },
        setting: {
          hide_food_prices: organization.hide_food_prices || false,
          hide_percentage_restfood: organization.hide_percentage_restfood || false,
          default_percentage_restfood: organization.default_percentage_restfood || 0,
          fat_price: organization.fat_price || 0,
          protein_price: organization.protein_price || 0,
          lactose_price: organization.lactose_price || 0,
          amount_of_milk_moments_per_day: organization.amount_of_milk_moments_per_day,
          bonus_per_kg_milk: organization.bonus_per_kg_milk || 0,
          supplier: !!organization.supplier ? organization.supplier.toLowerCase() : null,
          co2_per_kg_milk: organization.co2_per_kg_milk
        },
      };
      const res = await axios.post("create_organization", org);
      if (res.status === 200) {
        commit("addOrganizationInCreate", res.data);
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("common.itemCreated", { msg: organization.name })
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
  },
  async updateOrganization({ commit }, organization) {
    commit(
      "addOrganizationIdToSingleOrganizationsLoading",
      organization.organization_id
    );
    this._vm.$toast.info(
      i18n.t("common.busyUpdatingItem", { msg: organization.name }),
      {
        timeout: false,
      }
    );
    try {
      const org = {
        id: organization.organization_id,
        organization: {
          name: organization.name,
          vitalisator:
            organization.vitalisator.constructor === Array
              ? organization.vitalisator[0]
              : organization.vitalisator,
          label: organization.label,
          country: organization.country,
          mail_list: organization.mail_list || [],
          location: organization.location,
          hidden: organization.hidden
        },
        setting: {
          hide_food_prices: organization.hide_food_prices || false,
          hide_percentage_restfood: organization.hide_percentage_restfood || false,
          default_percentage_restfood: organization.default_percentage_restfood || 0,
          fat_price: organization.fat_price || 0,
          protein_price: organization.protein_price || 0,
          lactose_price: organization.lactose_price || 0,
          amount_of_milk_moments_per_day: organization.amount_of_milk_moments_per_day,
          bonus_per_kg_milk: organization.bonus_per_kg_milk || 0,
          supplier: !!organization.supplier ? organization.supplier.toLowerCase() : null,
          co2_per_kg_milk: organization.co2_per_kg_milk
        },
      };
      const res = await axios.post("update_organization", org);
      if (res.status === 204) {
        commit("removeOrganization", organization.organization_id);
        if(!!organization.supplier){
          organization.supplier = organization.supplier.toLowerCase()
        }
        commit("addOrganizationInUpdate", organization);
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("common.itemUpdated", { msg: organization.name })
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removeOrganizationIdFromSingleOrganizationsLoading",
        organization.organization_id
      );
    }
  },
  async deleteOrganization({ commit }, organization) {
    commit(
      "addOrganizationIdToSingleOrganizationsLoading",
      organization.organization_id
    );
    this._vm.$toast.info(
      i18n.t("common.busyDeletingItem", { msg: organization.name }),
      {
        timeout: false,
      }
    );
    try {
      const res = await axios.post("delete_organization", {
        id: organization.organization_id,
      });
      if (res.status === 204) {
        commit("removeOrganization", organization.organization_id);
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("common.itemDeleted", { msg: organization.name })
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removeOrganizationIdFromSingleOrganizationsLoading",
        organization.organization_id
      );
    }
  },

  // users
  async getAllUsers({ commit }) {
    commit("clearSingleUsersLoading");
    commit("setAllUsersLoading", true);
    try {
      const res = await axios.get("get_all_users?scope=all");
      if (res.status === 200) {
        commit("setUsers", res.data);
      } else {
        commit("setUsers", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllUsersLoading", false);
    }
  },
  async createUser({ dispatch }, user) {
    this._vm.$toast.info(i18n.t("common.busyCreatingItem", { msg: user.email }), {
      timeout: false,
    });
    try {
      const res = await axios.post("create_user", user);
      if (res.status === 200) {
        await dispatch("getAllUsers");
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.userCreated", { msg: user.email }));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }
    return null;
  },
  async updateUser({ commit, dispatch }, user) {
    commit("addUserIdToSingleUsersLoading", user.email);
    this._vm.$toast.info(i18n.t("common.busyUpdatingItem", { msg: user.email }), {
      timeout: false,
    });
    try {
      const res = await axios.post("update_user", user);
      if (res.status === 204) {
        await dispatch("getAllUsers");
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("common.itemUpdated", { msg: user.email })
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeUserIdFromSingleUsersLoading", user.email);
    }
  },
  async deleteUser({ commit, dispatch }, user) {
    commit("addUserIdToSingleUsersLoading", user.email);
    this._vm.$toast.info(
      i18n.t("common.busyDeletingItem", { msg: user.email }),
      {
        timeout: false,
      }
    );
    try {
      const res = await axios.post("delete_user", { id: user.uid });
      if (res.status === 204) {
        await dispatch("getAllUsers");
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("common.itemDeleted", { msg: user.email })
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("removeUserIdFromSingleUsersLoading", user.email);
    }
  },
  async resetPassword({}, user) {
    this._vm.$toast.info(i18n.t("common.busyGeneratingPasswordLink"), {
      timeout: false,
    });
    try {
      const res = await axios.post("reset_password", {
        email: user.email,
      });
      if (res.status === 200) {
        this._vm.$toast.clear();
        this._vm.$toast.success(i18n.t("common.passwordResetted", {msg: user.email}));
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    }

    return null;
  },

  // notifications
  async sendNotification(context, notification) {
    await axios.post("send_notification", notification);
  },

  // settings
  async updateSupplier({ commit, dispatch }, supplierValues) {
    commit("setUpdateSupplierLoading", true);
    this._vm.$toast.info(
      i18n.t("settings.updatingOrganizations", { msg: supplierValues.supplier }),
      {
        timeout: false,
      }
    );
    try {
      const reqBody = {
        supplier: supplierValues.supplier,
        fat_price: supplierValues.fatPrice,
        protein_price: supplierValues.proteinPrice,
        lactose_price: supplierValues.lactosePrice,
      };
      const res = await axios.post("update_organization_by_supplier", reqBody);
      if (res.status === 204) {
        await dispatch("getAllOrganizations");
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("settings.organizationsUpdated", { msg: supplierValues.supplier })
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit("setUpdateSupplierLoading", false);
    }
  },

  // monitor settings
  async getAllMonitorSettings({ commit }) {
    commit("clearSingleMonitorSettingsLoading");
    commit("setAllMonitorSettingsLoading", true);
    try {
      const res = await axios.get("get_all_monitor_settings");
      if (res.status === 200) {
        commit("setMonitorSettings", res.data);
      } else {
        commit("setMonitorSettings", []);
      }
    } catch (e) {
      this._vm.$toast.error(i18n.t("common.errorFetchingData"));
      console.error(e);
    } finally {
      commit("setAllMonitorSettingsLoading", false);
    }
  },
  async updateMonitorSettings({ commit }, monitorSettings) {
    commit(
      "addMonitorSettingsIdToSingleMonitorSettingsLoading",
      monitorSettings.organization_id
    );
    this._vm.$toast.info(
      i18n.t("common.busyUpdatingMonitorSettings"),
      {
        timeout: false,
      }
    );
    try {
      const objToSend = {
        organization_id: monitorSettings.organization_id,
        settings: monitorSettings.monitorSettings
      }
      const res = await axios.post("update_monitor_settings", objToSend);
      if (res.status === 204) {
        commit("removeMonitorSettings", monitorSettings.organization_id);
        commit("addMonitorSettings", monitorSettings);
        this._vm.$toast.clear();
        this._vm.$toast.success(
          i18n.t("common.monitorSettingsUpdated")
        );
      }
    } catch (e) {
      this._vm.$toast.clear();
      this._vm.$toast.error(i18n.t("common.errorRefreshPage"));
      console.error(e);
    } finally {
      commit(
        "removeMonitorSettingsIdFromSingleMonitorSettingsLoading",
        monitorSettings.organization_id
      );
    }
  },

};

// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  // POT COURSES
  setAllPotCoursesLoading(state, bool) {
    state.potCoursesLoading.allPotCourses = bool;
  },

  // ORGANIZATIONS
  setOrganizations(state, organizations) {
    state.organizations = organizations;
    // split mail list and make vitalisator an array (needed for v-combobox)
    state.organizations.forEach((org, index) => {
      state.organizations[index].vitalisator = [org.vitalisator];
      state.organizations[index].mail_list = org.mail_list
        .split(",")
        .filter((x) => x !== "");
    });
  },
  addOrganizationInCreate(state, organization) {
    organization.vitalisator = [organization.vitalisator];
    organization.mail_list = organization.mail_list
      .split(",")
      .filter((x) => x !== "");
    state.organizations.push(organization);
  },
  addOrganizationInUpdate(state, organization) {
    state.organizations.push(organization);
  },
  removeOrganization(state, id) {
    for (var i = 0; i < state.organizations.length; i++) {
      if (state.organizations[i].organization_id === id) {
        state.organizations.splice(i, 1);
      }
    }
  },
  setAllOrganizationsLoading(state, bool) {
    state.organizationsLoading.allOrganizations = bool;
  },
  addOrganizationIdToSingleOrganizationsLoading(state, id) {
    state.organizationsLoading.singleOrganizations.push(id);
  },
  removeOrganizationIdFromSingleOrganizationsLoading(state, id) {
    for (
      var i = 0;
      i < state.organizationsLoading.singleOrganizations.length;
      i++
    ) {
      if (state.organizationsLoading.singleOrganizations[i] === id) {
        state.organizationsLoading.singleOrganizations.splice(i, 1);
      }
    }
  },
  clearSingleOrganizationsLoading(state) {
    state.organizationsLoading.singleOrganizations = [];
  },

  // USERS
  setUsers(state, users) {
    state.users = users.map((u) => ({
      email: u.email,
      type: u.customClaims.admin ? "admin" : "user",
      uid: u.uid,
      organizations: u.customClaims.organizations
        ? u.customClaims.organizations.split(",").filter((x) => x !== "")
        : [],
    }));
  },
  addUser(state, user) {
    user.type = user.type ? "admin" : "user";
    state.users.push(user);
  },
  removeUser(state, email) {
    for (var i = 0; i < state.users.length; i++) {
      if (state.users[i].email === email) {
        state.users.splice(i, 1);
      }
    }
  },
  setAllUsersLoading(state, bool) {
    state.usersLoading.allUsers = bool;
  },
  addUserIdToSingleUsersLoading(state, email) {
    state.usersLoading.singleUsers.push(email);
  },
  removeUserIdFromSingleUsersLoading(state, email) {
    for (var i = 0; i < state.usersLoading.singleUsers.length; i++) {
      if (state.usersLoading.singleUsers[i] === email) {
        state.usersLoading.singleUsers.splice(i, 1);
      }
    }
  },
  clearSingleUsersLoading(state) {
    state.usersLoading.singleUsers = [];
  },

  // ADMINS
  setAdmins(state, admins) {
    state.admins = admins;
  },

  // SETTINGS
  setUpdateSupplierLoading(state, bool) {
    state.settings.updateSupplierLoading = bool;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },

  // MONITOR SETTINGS
  setMonitorSettings(state, monitorSettings) {
    state.monitorSettings = monitorSettings;
  },
  addMonitorSettings(state, monitorSettings) {
    state.monitorSettings.push(monitorSettings);
  },
  removeMonitorSettings(state, id) {
    for (var i = 0; i < state.monitorSettings.length; i++) {
      if (state.monitorSettings[i].organization_id === id) {
        state.monitorSettings.splice(i, 1);
      }
    }
  },
  setAllMonitorSettingsLoading(state, bool) {
    state.monitorSettingsLoading.allMonitorSettings = bool;
  },
  addMonitorSettingsIdToSingleMonitorSettingsLoading(state, id) {
    state.monitorSettingsLoading.singleMonitorSettings.push(id);
  },
  removeMonitorSettingsIdFromSingleMonitorSettingsLoading(state, id) {
    for (var i = 0; i < state.monitorSettingsLoading.singleMonitorSettings.length; i++) {
      if (state.monitorSettingsLoading.singleMonitorSettings[i] === id) {
        state.monitorSettingsLoading.singleMonitorSettings.splice(i, 1);
      }
    }
  },
  clearSingleMonitorSettingsLoading(state) {
    state.monitorSettingsLoading.singleMonitorSettings = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
