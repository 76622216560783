import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth } from "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "password_reset" */ "../views/PasswordReset.vue"
      ),
  },
  {
    path: "/app",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "app_index" */ "../views/Index.vue"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "overview",
        name: "AppOverzicht",
        component: () =>
          import(
            /* webpackChunkName: "app_home" */ "../views/app/Overview.vue"
          ),
      },
      {
        path: "pots",
        name: "Potten",
        component: () =>
          import(/* webpackChunkName: "app_pots" */ "../views/app/Pots.vue"),
      },
      {
        path: "feed",
        name: "Voersoorten",
        component: () =>
          import(/* webpackChunkName: "app_foods" */ "../views/app/Foods.vue"),
      },
      {
        path: "measurements",
        name: "Metingen",
        component: () =>
          import(
            /* webpackChunkName: "app_foods" */ "../views/app/Measurements.vue"
          ),
      },
      {
        path: "pickup_moments",
        name: "Ophaalmomenten",
        component: () =>
          import(
            /* webpackChunkName: "app_pickupMoments" */ "../views/app/PickupMoments.vue"
          ),
      },
      {
        path: "pot_course",
        name: "Potverloop",
        component: () =>
          import(
            /* webpackChunkName: "app_pickupMoments" */ "../views/app/PotCourse.vue"
          ),
      },
      {
        path: "memos",
        name: "Memos",
        component: () =>
          import(
            /* webpackChunkName: "app_pickupMoments" */ "../views/app/Memos.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin_index" */ "../views/Index.vue"),
    meta: {
      authRequired: true,
      adminRequired: true
    },
    children: [
      {
        path: "overview",
        name: "AdminOverzicht",
        component: () =>
          import(
            /* webpackChunkName: "admin_overview" */ "../views/admin/Overview.vue"
          ),
      },
      {
        path: "organizations",
        name: "Bedrijven",
        component: () =>
          import(
            /* webpackChunkName: "admin_import" */ "../views/admin/Organizations.vue"
          ),
      },
      {
        path: "users",
        name: "Gebruikers",
        component: () =>
          import(
            /* webpackChunkName: "admin_users" */ "../views/admin/Users.vue"
          ),
      },
      {
        path: "settings",
        name: "Instellingen",
        component: () =>
          import(
            /* webpackChunkName: "admin_monitor" */ "../views/admin/Settings.vue"
          ),
      },
      {
        path: "monitor",
        name: "Monitor",
        component: () =>
          import(
            /* webpackChunkName: "admin_monitor" */ "../views/admin/Monitor.vue"
          ),
      },
    ],
  },
  {
    path: '/pdf',
    name: 'PDF',
    component: () =>
      import(/* webpackChunkName: "pdf" */ "../views/Pdf.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // This gets the current user, and as a side effect blocks the current route from rendering until Firebase Auth is done loading
  // This way store.state.auth.user is never in a in-between state
  const user = await getCurrentUser();

  const authRequired = to.matched.some((record) => record.meta.authRequired);
  const adminRequired = to.matched.some((record) => record.meta.adminRequired);

  if (authRequired) {
    // Check if user is logged in
    if (user) {
      if (adminRequired) {
        const tokenResult = await user.getIdTokenResult();
        if (tokenResult.claims.admin) {
          next();
        } else {
          next({
            path: "/",
          });
        }
      } else {
        next();
      }
    } else {
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export default router;
